@import './scss/index.scss';

.admin-sider {
  position: fixed;
  left: -15px;
  height: 100vh;
  overflow: auto;
}

.boton {
  background-color: rgba(235, 183, 13, 0.911) !important;
  border-color: rgba(235, 183, 13, 0.911) !important;
  color: black !important;
}

.boton:hover {
  background-color: rgba(255, 217, 0, 0.966) !important;
}

.titulos-home {
  background-color: rgb(240, 209, 71);
  width: 50%;
  margin: 0 auto;
  font-weight: bolder;
  border-radius: 30px;
  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.titulos-exp {
  background-color: rgb(223, 187, 27);
  width: 50%;
  border-radius: 30px;
  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.titulos-exp2 {
  background-color: rgb(234, 235, 227);
  width: 50%;
  border-radius: 30px;
  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.titulo-home {
  background-color: rgb(234, 235, 227);
  width: 50%;

  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

#home-section {
  background: url(./assets/home2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 780px;
}

#home-section .dark-overlay {
  position: relative;
  /* top: 0;
  left:0; */
  width: 100%;
  min-height: 700px;
  background: rgba(0, 0, 0, 0.4);
  padding-top: 80px;
}

#home-section .home-inner {
  padding-top: 150px;
}

#home-section .card-form {
  opacity: 0.9;
}

#home-section .fas,
#create-section .fas {
  color: rgb(212, 161, 20);
  background: #fff;
  padding: 5px;
  border-radius: 5px;
}

#explore-section .fas,
#share-section .fas {
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.logo {
  width: 80%;
}

a.nav-link {
  color: #fff;
}

.decoracion {
  color: #000;
}

.decoracion:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .card {
    margin-top: 100px;
  }

  .hugol {
    margin-top: 100px;
  }
}

.modalh {
  color: #fff;
}

#card_principal {
  background-color: rgb(230, 197, 16);
}

.card_landing {
  background-color: rgb(212, 161, 20);
}

.principal {
  margin-top: -100px;
}

.carousel-item {
  height: 450px;
}

.carousel-image-1 {
  background: url('./assets/fondo1.jpg');
  background-size: cover;
}

.carousel-image-2 {
  background: url('./assets/home1.jpg');
  background-size: cover;
}

.carousel-image-3 {
  background: url('./assets/insol.jpg');
  background-size: cover;
}

.text_color {
  color: rgb(212, 161, 20);
}

#page-header {
  height: 200px;
  background: url('./assets/image1.jpg');
  background-position: 0 -360px;
  background-attachment: fixed;
  color: #fff;
  border-bottom: 1px #eee solid;
  padding-top: 50px;
  width: 100%;
}

.avisofinde {
  background-color: #333;
  padding: 10px;
  font-size: 22px;
  margin-top: -80px;
}
